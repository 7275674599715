<template>
  <v-row>
    <v-col>
      {{ subject }}
    </v-col>
    <v-col v-if="type !== 'schoolClass'">
      <router-link
        class="text-decoration-none"
        :to="{
          name: 'TimetableCode',
          params: { code: schoolClass.code },
        }"
        v-for="schoolClass in schoolClasses"
        :key="schoolClass.id"
        >{{ schoolClass.code }}
      </router-link>
    </v-col>
    <v-col v-if="type !== 'teacher'">
      <router-link
        class="text-decoration-none"
        :to="{
          name: 'TimetableCode',
          params: { code: teacher.code },
        }"
        v-for="teacher in teachers"
        :key="teacher.id"
        >{{ teacher.code }}
      </router-link>
    </v-col>
    <v-col v-if="type !== 'room'">
      <router-link
        class="text-decoration-none"
        :to="{ name: 'TimetableCode', params: { code: room.code } }"
        v-for="room in rooms"
        :key="room.id"
        >{{ room.code }}
      </router-link>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    type: { type: String, default: '' },
    value: { type: Object, default: null },
  },
  computed: {
    teachers() {
      return this.value ? this.value.teachers || [] : [];
    },
    schoolClasses() {
      return this.value ? this.value.schoolClasses || [] : [];
    },
    subject() {
      return this.value ? this.value.subject.code : '';
    },
    rooms() {
      return this.value ? this.value.rooms || [] : [];
    },
  },
};
</script>
