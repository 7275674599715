var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.subject)+" ")]),(_vm.type !== 'schoolClass')?_c('v-col',_vm._l((_vm.schoolClasses),function(schoolClass){return _c('router-link',{key:schoolClass.id,staticClass:"text-decoration-none",attrs:{"to":{
        name: 'TimetableCode',
        params: { code: schoolClass.code },
      }}},[_vm._v(_vm._s(schoolClass.code)+" ")])}),1):_vm._e(),(_vm.type !== 'teacher')?_c('v-col',_vm._l((_vm.teachers),function(teacher){return _c('router-link',{key:teacher.id,staticClass:"text-decoration-none",attrs:{"to":{
        name: 'TimetableCode',
        params: { code: teacher.code },
      }}},[_vm._v(_vm._s(teacher.code)+" ")])}),1):_vm._e(),(_vm.type !== 'room')?_c('v-col',_vm._l((_vm.rooms),function(room){return _c('router-link',{key:room.id,staticClass:"text-decoration-none",attrs:{"to":{ name: 'TimetableCode', params: { code: room.code } }}},[_vm._v(_vm._s(room.code)+" ")])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }